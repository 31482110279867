import React, { Fragment } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { ChevronLeft } from 'react-feather'
import Content from '../components/Default/Content'
import Layout from '../components/Default/Layout'
import './SinglePost.css'
import { Helmet } from 'react-helmet'

export const SinglePostTemplate = ({
  title,
  date,
  body,
  image,
  dateEN,
  nextPostURL,
  prevPostURL,
  metaDescription,
  categories = []
}) => {
  const imageSafe = image ? image.relativePath : ''
  return (
    <main>
      <Helmet>
        <script type="application/ld+json">{`
  
  {
    "@context":"https://schema.org",
    "@type":"BlogPosting",
    "headline":"${title}",
    "description":"${metaDescription}",
    "datePublished":"${dateEN}",
    "dateModified":"${dateEN}",
    "mainEntityOfPage":"True",
    "image":{
       "@type":"imageObject",
       "url":"https://danovekontroly.cz/images/${imageSafe}",
       "height":"600",
       "width":"800"
    },
    "publisher":{
       "@type":"Organization",
       "name":"EK Partners",
       "logo":{
          "@type":"imageObject",
          "url":"https://danovekontroly.cz/images/hp-logo.png"
       }
    },
    "author":{
       "@type":"Person",
       "name":"Lukáš Eisenwort"
    },
    "articleBody":"${title}"
 }`}</script>
      </Helmet>
      <article className="SinglePost section light">
        <div className="container skinny">
          <Link className="SinglePost--BackButton" to="/aktuality/">
            <ChevronLeft /> Zpět
          </Link>
          <div className="SinglePost--Content relative">
            <div className="SinglePost--Meta">
              {date && (
                <time
                  className="SinglePost--Meta--Date"
                  itemProp="dateCreated pubdate datePublished"
                  dateTime={date}
                >
                  {date}
                </time>
              )}
              {categories && (
                <Fragment>
                  <span>|</span>
                  {categories.map((cat, index) => (
                    <span
                      key={cat.category}
                      className="SinglePost--Meta--Category"
                    >
                      {cat.category}
                      {/* Add a comma on all but last category */}
                      {index !== categories.length - 1 ? ',' : ''}
                    </span>
                  ))}
                </Fragment>
              )}
            </div>

            {title && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}

            <div className="SinglePost--InnerContent">
              <Content source={body} />
            </div>

            <div className="SinglePost--Pagination">
              {prevPostURL && (
                <Link
                  className="SinglePost--Pagination--Link prev"
                  to={prevPostURL}
                >
                  Předchozí článek
                </Link>
              )}
              {nextPostURL && (
                <Link
                  className="SinglePost--Pagination--Link next"
                  to={nextPostURL}
                >
                  Další článek
                </Link>
              )}
            </div>
          </div>
        </div>
      </article>
    </main>
  )
}

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <Helmet>
        {_get(thisEdge, 'next.fields.slug') && (
          <link
            rel="next"
            href={`https://danovekontroly.cz${_get(
              thisEdge,
              'next.fields.slug'
            )}`}
          />
        )}

        {_get(thisEdge, 'previous.fields.slug') && (
          <link
            rel="prev"
            href={`https://danovekontroly.cz${_get(
              thisEdge,
              'previous.fields.slug'
            )}`}
          />
        )}
      </Helmet>
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
        metaDescription={post.frontmatter.meta || false}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        image {
          relativePath
        }
        date(locale: "cs", formatString: "DD. MM. YYYY")
        dateEN: date(locale: "en")
        categories {
          category
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "aktuality" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
